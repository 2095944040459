export class RandomColor {

    /**
     * Generate a random HEX color
     *
     * @returns HEX format color
     * @memberof RandomColor
     */
    static generateColor(): string {
        const randomColorHEX = ('#' + Math.random().toString(16).slice(2, 8));
        return randomColorHEX;
    }
}
