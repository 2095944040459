import { Directive, ElementRef, Input,
         OnInit } from '@angular/core';
import { RandomColor } from './random-color.utils';

@Directive({
  selector: '[rcRandomColor]'
})
export class RandomColorDirective implements OnInit {
  // Randomize BG color
  @Input() bgColor: string[];
  // Randomize text color
  @Input() textColor: string[];

  constructor(private _el: ElementRef) { }

  ngOnInit() {
    let color;
    // BG color
    if (this.bgColor) {
      if (this.bgColor.length === 0) {
        color = RandomColor.generateColor();
      } else {
        color = this.bgColor[this.randomArrayIndex(this.bgColor)];
      }
      this._el.nativeElement.style.backgroundColor = color;
    }
    // Text color
    if (this.textColor) {
      if (this.textColor.length === 0) {
        color = RandomColor.generateColor();
      } else {
        color = this.textColor[this.randomArrayIndex(this.textColor)];
      }
      this._el.nativeElement.style.color = color;
    }
  }

  /**
   * Random index value between 0 - array.length
   *
   * @param arr array to get the index from
   * @returns random index
   * @memberof RandomColorDirective
   */
  private randomArrayIndex(arr: any[]): number {
    return Math.floor(Math.random() * arr.length);
  }

}
